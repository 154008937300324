@include media-breakpoint-up(lg) {
  .step0--main__wizard-form {
    display: flex;
    padding-block: 2rem;
    padding-inline: 80px;
    height: calc(100% - 200px);
  }

  .step0--main__form-title {
    font-size: 18px;
    text-align: start;
  }
}
