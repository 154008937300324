.multi-select {
  cursor: pointer;
  max-width: 100%;
}

.select--field {
  display: none; /* Oculta el elemento select original */
}

.multi-select__default-option {
  cursor: pointer !important;
  gap: 4px;
  width: 85%;
  overflow-x: scroll;
  scrollbar-width: none;
  position: absolute;
  box-sizing: border-box;
 }

.multi-options {
  border: 1px solid rgba(135, 135, 135, 0.804); 
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  z-index: 100;
  max-height: 200px;
  overflow-y: scroll;
  top: 25px;
  position: relative;
}

.multi-select__current-option {
  cursor: pointer;
  flex: 0 0 auto;
  background-color: #606060;
}

.multi-select__current-option-with-border {
  border-bottom: 1px solid #999;
}

.disabled-select {
  background-color: #99999938;
  cursor: none;
}

.check-option  {
  width: 10px;
  height: 10px;
}
.check-option > img {
  filter: brightness(0) invert(100);
}

.multi-select__close-icon {
  filter: brightness(0) invert(100);
}

.check-option-selected {
  background-color: #E06E1F;
}