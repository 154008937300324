.custom-select {
  cursor: pointer;
}

.select--field {
  display: none; /* Oculta el elemento select original */
}

.select__default-option {
  cursor: pointer !important;
}

.application-options {
  border: 1px solid rgba(135, 135, 135, 0.804); 
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  z-index: 100;
  position: relative;
  height: 150px;
  overflow-y: scroll;
}

.select__current-option {
  cursor: pointer;
}

.select__current-option:hover {
  background-color: #c2c2c23d;
}

.select__current-option-with-border {
  border-bottom: 1px solid #999;
}

.select-input__custom:focus {
  outline: none;
}