.step0--main {
  height: fit-content;
}

.step0--main__wizard-form {
  display: block;
  padding-inline: 16px;
  height: calc(100% - 200px);
}

.step0--main__wizard-container {
  height: fit-content;
}

.step0--main__wizard-form > div {
  border-radius: 25px;
  background: rgba(232, 232, 232, 0.29);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.step0--main__form-title {
  color: #333;
  font-family: Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  text-align: center;
  line-height: normal;
}